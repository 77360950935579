var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.apiLoaded)?_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.editAppIndex == null ? 'Добавление заявки' : 'Редактирование заявки'))]),_c('a',{staticClass:"close",on:{"click":function($event){return _vm.$emit('close')}}},[_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z","fill":"#0033A0"}})])])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"form"},[_c('div',{},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"label":"Внешняя организация"},model:{value:(_vm.isExternalOrg),callback:function ($$v) {_vm.isExternalOrg=$$v},expression:"isExternalOrg"}})],1)],1),(!_vm.isExternalOrg)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Организация","items":_vm.ourOrgs,"item-text":"shortName","item-value":"id","return-object":"","outlined":"","dense":""},model:{value:(_vm.application.ourOrg),callback:function ($$v) {_vm.$set(_vm.application, "ourOrg", $$v)},expression:"application.ourOrg"}})],1)],1):_vm._e(),(_vm.isExternalOrg)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Название внешней организации","outlined":"","dense":""},model:{value:(_vm.application.differentOrgName),callback:function ($$v) {_vm.$set(_vm.application, "differentOrgName", $$v)},expression:"application.differentOrgName"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Ответственный","items":_vm.experts,"item-text":"fullName","item-value":"id","return-object":"","outlined":"","dense":"","rules":[
                  (v=>(v != null) || 'Поле не должно быть пустым'),
                ]},model:{value:(_vm.application.offerExpert),callback:function ($$v) {_vm.$set(_vm.application, "offerExpert", $$v)},expression:"application.offerExpert"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Сумма заявки, ₽","hint":'Снижение: ' + _vm.discount + '%',"rules":[v=>(v==null||v.length===0||!!_vm.normalizeNumber(v, 0, 1e13, 2)||'Введите корректную сумму')]},model:{value:(_vm.application.appPrice),callback:function ($$v) {_vm.$set(_vm.application, "appPrice", $$v)},expression:"application.appPrice"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"label":"Приоритетная заявка"},model:{value:(_vm.application.highPriority),callback:function ($$v) {_vm.$set(_vm.application, "highPriority", $$v)},expression:"application.highPriority"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Оценка риска","items":_vm.riskAssessmentDict,"item-text":"title","item-value":"code","outlined":"","dense":"","rules":[
                  (v=>(v != null) || 'Поле не должно быть пустым'),
                ]},model:{value:(_vm.application.riskAssessment),callback:function ($$v) {_vm.$set(_vm.application, "riskAssessment", $$v)},expression:"application.riskAssessment"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.application.offerExpert && !_vm.application.offerExpert.isRolePartner)?_c('v-select',{attrs:{"label":"Куратор","items":_vm.partners,"item-text":"fullName","item-value":"id","return-object":"","outlined":"","dense":""},model:{value:(_vm.application.curator),callback:function ($$v) {_vm.$set(_vm.application, "curator", $$v)},expression:"application.curator"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"label":"Получено согласие партнеров"},model:{value:(_vm.application.partnersApproved),callback:function ($$v) {_vm.$set(_vm.application, "partnersApproved", $$v)},expression:"application.partnersApproved"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(!_vm.application.partnersApproved)?_c('v-select',{attrs:{"label":"Партнеры, разделяющие риск","items":_vm.partners,"item-text":"fullName","item-value":"id","return-object":"","multiple":"","chips":"","outlined":"","dense":"","rules":[
                        (v=>(v != null && v.length > 0) || 'Поле не должно быть пустым'),
                      ]},model:{value:(_vm.application.shareRiskPartners),callback:function ($$v) {_vm.$set(_vm.application, "shareRiskPartners", $$v)},expression:"application.shareRiskPartners"}}):_vm._e()],1)],1)],1)])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticStyle:{"width":"100%"},on:{"click":_vm.save}},[_vm._v(" Сохранить ")])],1),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',[_c('a',{attrs:{"href":"https://supt.nisse.ru/publicfiles/Бизнес-процессы по проектной деятельности 270421_v4_чист.docx"}},[_vm._v("Описание процессов проектной деятельности.docx")])])])],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }